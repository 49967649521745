import { Link, useStaticQuery } from "gatsby"
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import FacebookIcon from '../assets/front/images/icons/facebook.svg'
import InstagramIcon from '../assets/front/images/icons/instagram.svg'


const SubMenu = (list,type) =>{
return (list.map((item,key) => {
  
  if (item.frontmatter.type===type) return <li key={key}><Link to={"/urunler/"+item.frontmatter.type+"-"+item.frontmatter.category} className={"text-"+item.frontmatter.category}>{item.frontmatter.name}</Link></li>
}))

}

const HeaderLinks =() => (
  <div className="header__links">
  <div className="header__social">
      <a href="https://www.facebook.com/BritCareTurkiye/" rel="noopener noreferrer" target="_blank" className="btn btn--gradient-primary btn--circle">
          <img src={FacebookIcon} alt="Facebook"/>
      </a>

      <a href="https://www.instagram.com/britturkiye/" rel="noopener noreferrer" target="_blank" className="btn btn--gradient-primary btn--circle">
          <img src={InstagramIcon} alt="Instagram"/>
      </a>

  </div>
  </div>
)




const Nav = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
  query ItemQuery{
    allMarkdownRemark {
      nodes {
        frontmatter {
          type
          name
          category
        }
      }
    }
  }
  `)
  
  const list = data.allMarkdownRemark.nodes
  const [isVisible, setIsVisible] = useState(false);
    const toggleVisible = () => {
      setIsVisible(!isVisible);
    };
    
  
  
  return (
    <>
    <Helmet> <body className={isVisible ? 'is--navigation-open' : 'memo'} ></body></Helmet>
  <div className ="header__nav">
  
    <div className = "header__menu">
      <nav className="navigation">
      <ul>
          <li><Link to="/#cats">KEDİ</Link>
          <ul>{SubMenu(list,'cat')}</ul></li>
          <li><Link to="/#dogs">KÖPEK</Link><ul>{SubMenu(list,'dog')}</ul></li>
          <li >
          <Link to="/hakkimizda/" >HAKKIMIZDA</Link>
          </li>
          <li className="navigationPrimary__item">
          <Link to="/iletisim/" >İLETİŞİM</Link>
          </li>
          </ul>
      
      </nav>
    </div>
    <HeaderLinks/>
    </div>
    <div className="header__hamburger">
                <button type="button" className="header__hamburger hamburger" id="hamburger" onClick={toggleVisible}>
                        <span id="morph-shape" className="hamburger__morphShape" data-morph-open="M3,20c0,0,12-4,27-4s27,4,27,4;M3,60c0,0,12,4,27,4s27-4,27-4" data-morph-close="M3,20c0,0,12,4,27,4s27-4,27-4;M3,60c0,0,12-4,27-4s27,4,27,4">
                            <svg width="100%" height="100%" viewBox="0 0 60 80" preserveAspectRatio="none" shapeRendering="geometricPrecision">
                                <path d="M3,20c0,0,12,0,27,0s27,0,27,0" fill="#ffffff" />
                                <line x1="3" y1="40" x2="57" y2="40" fill="#ffffff" />
                                <path d="M3,60c0,0,12,0,27,0s27,0,27,0" fill="#ffffff" />
                            </svg>
                        </span>
                </button>
            </div>
    </>
  )}




export default Nav
