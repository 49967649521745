import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"
import Nav from "./navigation"

const Header = ({ siteTitle }) => (
  <header className ="header">
  
    <div className = "container">
      <div className="header__content">
        <div className="header__logo">
          <Link to="/" style ={{display: 'inline-block'}}>
            
            <Logo/>{siteTitle}
          </Link>
        </div>
        <Nav/>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
