/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "./logo"
import AtlasLogo from '../assets/front/images/atlaspet-logo.svg'

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    
     <div className="app">
      <Header siteTitle={data.site.siteMetadata.title} />
      
        <main className="main" role="main">{children}</main>
        <footer className = "footer" role="contentinfo">
        <div className="container">
        <div className="footer__content py-2 py-md-0">
            <div className="row justify-content-between align-items-center">
                <div className="col-12 col-md-auto">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-auto text-center text-md-left py-2 py-md-4">
                            <Logo height="30" alt="Logo"/>
                        </div> 
                        <div className="col-12 col-md text-center text-md-left ppy-2 py-md-4">
                            <p className="font-weight-bold pt-1">&copy; {new Date().getFullYear()} VAFO PRAHA s.r.o.; Atlaspet A.Ş. tüm hakları mahfuzdur.</p>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md-auto text-center text-md-left py-2 py-md-4">
                    <a href="https://atlaspet.com.tr" target="_blank" rel="noopener noreferrer">
                    <img src={AtlasLogo} height="30" alt="Atlaspet Logo"/>
                    </a>
                </div>
            </div>
        </div>

    </div>
          
        </footer>
      </div>
      
    
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


